<template>
    <div>
        <CRow>
            <CCol md="12">
                <CCard>
                    <CCardHeader>
                        <CRow>
                            <CCol md="12">
                                Tambah Kelompok Jenis Tanaman
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CForm v-on:submit.prevent="storeProcess" class="fwdwd">
                        <CCardBody>
                            <div class="form-group">
                                <label for=""><b>Nama Kelompok Jenis Tanaman <span class="text-danger">*</span></b></label>
                                <input type="text" class="form-control" placeholder="Nama Kelompok Jenis Tanaman" v-model="tk_nama">
                            </div>
                        </CCardBody>
                        <CCardFooter>
                            <div class="text-right">
                                <button type="submit" class="btn btn-primary text-white btn-hover-outline-primary"><i class="fa fa-save"></i> Simpan</button>
                            </div>
                        </CCardFooter>
                    </CForm>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
    const null_array = [];
    export default {
        name: "AddJenisTanaman",
        components: {
        },
        data() {
            return {
                
                tk_nama: '',
            }
        },
        methods: {
            storeProcess: function () {
                let self = this;
                Swal.fire({
                    title: '<i class="fa fa-refresh fa-spin"></i>',
                    text: "Loading...",
                    showConfirmButton: false,
                    allowOutsideClick: false,
                });
                var FormData = require('form-data');
                var data = new FormData();
                data.append('tk_nama',this.tk_nama);
                var config = {
                  method: 'post',
                  url: this.apiLink+'api/crud/tanaman_kategori',
                  headers: { 
                    'Authorization': 'Bearer '+this.access_token, 
                  },
                  data : data
                };
                axios(config)
                .then(function (response) {
                    var res_data = response.data;
                    if (res_data.meta.code != "200") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: res_user.data.message
                        });
                    }
                    else{
                        let timerInterval;
                        Swal.fire({
                            icon: "success",
                            title: "Berhasil",
                            text: "Anda akan diarahkan ke halaman master jenis tanaman segera",
                            timer: 2000,
                            timerProgressBar: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            didOpen: () => {
                                timerInterval = setInterval(() => {
                                    const content = Swal.getContent();
                                    if (content) {
                                        const b = content.querySelector("b");
                                        if (b) {
                                            b.textContent = Swal.getTimerLeft();
                                        }
                                    }
                                }, 100);
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                            },
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                self.$router.push({name: 'AllKelompokJenisTanaman'});
                            }
                        }).catch((error) => {
                            var error_res = error.response.data.meta;
                            if (error_res.code != 200) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: error_res.message
                                });
                            }
                        });
                    }
                });
            },
        }
    };
</script>